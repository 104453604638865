import React from "react";
import { Link } from "react-scroll";
import styled, { keyframes } from "styled-components";
import { pulse } from "react-animations";
import Slogan from "../assets/logos/signature_slp-02.svg";
import Arrow from "../assets/icons/arrow_down.svg";
import HeaderImage from "../assets/images/header.jpg";
import HeaderImageMobile from "../assets/images/header-mobile.jpg";
import WhiteLogo from "../assets/logos/logo_slp_white.svg";
import { ParallaxBanner } from "react-scroll-parallax";
// import { COLORS } from "../../style-vars.js";
import ReactPlayer from "react-player";

class MyHeader extends React.Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
      isPlaying: false
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleOpenModal() {
    this.setState({ showModal: true, isPlaying: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false, isPlaying: false });
  }
  render() {
    const isPlaying = this.state.isPlaying;

    return (
      <div className="header">
        <StyledModal
          isOpen={this.state.showModal}
          onClick={this.handleCloseModal}
        >
          <DemoDiv>
            <ReactPlayer
              url={"http://www.youtube.com/watch?v=PxflK9d4AMw?&autoplay=0"}
              playing={isPlaying}
            />
          </DemoDiv>
        </StyledModal>
        <ParallaxBanner
          layers={[
            {
              image: HeaderImage,
              amount: 0.6
            }
          ]}
          style={{
            height: "100vh"
          }}
        >
          <SectionHeader>
            <HeaderContentSlogan>
              <HeaderLogo>
                <img alt="" src={WhiteLogo} />
              </HeaderLogo>
              <HeaderSlogan>
                <img alt="" src={Slogan} />
              </HeaderSlogan>
              {/* <CTA>
                <p>
                  <strong>
                    <GreenWord>LATEST ANNOUNCEMENT</GreenWord>
                  </strong>{" "}
                  <br></br>
                  Smartlamppost Marketplace BETA has just been launched!
                </p>
                <ButtonContainer>
                  <a
                    href="https://platform.smartlamppost.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StyledButton>Go to Marketplace</StyledButton>
                  </a>

             
                </ButtonContainer>
              </CTA> */}
            </HeaderContentSlogan>
            <HeaderArrow>
              <Link
                activeClass="active"
                to="solution"
                spy={true}
                smooth={true}
                offset={-64}
                duration={500}
              >
                <ArrowDown src={Arrow} />
              </Link>
            </HeaderArrow>
          </SectionHeader>
        </ParallaxBanner>
      </div>
    );
  }
}
export default MyHeader;

// const GreenWord = styled.span`
//   color: ${COLORS.GREEN};
// `;

// ------- HEADER ------- //

const SectionHeader = styled.div`
  position: relative;
  background-color: black;
  height: 100vh;
  min-height: 3rem;
  width: 100%;
  overflow: hidden;
  background: url(${HeaderImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media only screen and (max-width: 500px) {
    background: url(${HeaderImageMobile});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
  }
  /* & > img {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  } */
`;

const HeaderContentSlogan = styled.div`
  position: relative;
  height: 90%;
  display: flex;
  flex-direction: column;
  z-index: 2;
`;

const HeaderSlogan = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  z-index: 1;
  margin-left: 7%;
  margin-bottom: 24px;
  img {
    width: 600px;
    margin-top: 24px;
  }
  @media only screen and (max-width: 1024px) {
    img {
      width: 500px;
    }
  }
  @media only screen and (max-width: 500px) {
    img {
      width: 250px;
    }
  }
`;

const HeaderLogo = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  z-index: 1;
  margin-top: auto;
  margin-left: 7%;
  img {
    width: 350px;
    margin-bottom: 0;
  }
  @media only screen and (max-width: 1024px) {
    img {
      width: 200px;
    }
  }
  @media only screen and (max-width: 500px) {
    margin-top: 12%;
  }
`;

// const CTA = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-self: flex-start;
//   margin-left: 7%;
//   margin-top: 80px;

//   p {
//     color: white;
//     width: 100%;
//     font-size: 18px;
//     line-height: 26px;
//   }

//   button {
//     height: fit-content;
//     width: fit-content;
//     border: none;
//     font-size: 14px;
//     line-height: 24px;
//     border-radius: 16px;
//     text-transform: uppercase;
//     background-color: ${COLORS.GREEN};
//     color: ${COLORS.GREY};
//     padding: 12px 24px;
//     margin-right: 1rem;
//     font-weight: bold;
//     cursor: pointer;
//   }
//   @media only screen and (max-width: 500px) {
//     margin-right: 7%;
//   }
// `;

const HeaderArrow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const bounceAnimation = keyframes`${pulse}`;

const ArrowDown = styled.img`
  cursor: pointer;
  z-index: 2;
  position: relative;
  width: fit-content;
  width: 32px;
  -webkit-animation: ${bounceAnimation} 1s ease-in 1s infinite;
  animation: ${bounceAnimation} 1s ease-in 1s infinite;
`;

// const ButtonContainer = styled.div`
//   display: flex;
//   flex-direction: row;
// `;

const StyledModal = styled.div`
  display: ${props => (props.isOpen ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: rgba(160, 160, 160, 0.4);
  height: 100%;
  width: 100%;
  z-index: 200;
`;
const DemoDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 4rem;
`;

// const StyledButton = styled.button`
//   height: fit-content;
//   width: fit-content;
//   border: none;
//   font-size: 14px;
//   line-height: 24px;
//   border-radius: 16px;
//   text-transform: uppercase;
//   background-color: ${COLORS.GREEN};
//   color: ${COLORS.GREY};
//   padding: 12px 24px;
//   margin-right: 1rem;
//   font-weight: bold;
//   cursor: pointer;
// `;
